import { Box } from "@mui/material"
import React from "react"
import { A, H2, P } from "../components/material-ui"
import { SEO } from "../layouts/"

const PageNotFound: React.FC = () => {
  return (
    <Box>
      <SEO title="Page not found" description="Page not found" />
      <Box
        sx={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box>
          <Box style={{ maxWidth: "50vw" }} component="img" src="/images/logo-460x460.png" />
        </Box>
        <Box sx={{ textAlign: "center" }}>
          <H2>Page Not Found</H2>
          <P>
            Doh! We couldn&apos;t find that page. Use <A href="/">this link</A> to return to safety.
          </P>
        </Box>
      </Box>
    </Box>
  )
}
export default PageNotFound
